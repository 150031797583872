// import { Controller } from "@hotwired/stimulus";

// export default class extends Controller {
// 	static targets = ["graphsContainer", "newsContainer", "newsExpandButton", "newsCloseButton", "newsImage",
// 					"resourcesContainer", "tsaContainer", "tsaExpandButton", "tsaCloseButton",
// 					"videosContainer", "videosExpandButton", "videosCloseButton", "mainVideo", "videoCarousel"];
// 	static classes = [ "newsImageSize" ]

// 	expandTsasContainer(){
// 		this.tsaContainerTarget.classList.add("col-span-5");
// 		this.tsaContainerTarget.classList.remove("col-span-3");

// 		this.newsContainerTarget.classList.add("hidden");
// 		this.resourcesContainerTarget.classList.add("hidden");

// 		this.tsaExpandButtonTarget.classList.add("hidden");
// 		this.tsaCloseButtonTarget.classList.remove("hidden");
// 	}

// 	shrinkTsasContainer(){
// 		this.tsaContainerTarget.classList.remove("col-span-5");
// 		this.tsaContainerTarget.classList.add("col-span-3");

// 		this.newsContainerTarget.classList.remove("hidden");
// 		this.resourcesContainerTarget.classList.remove("hidden");

// 		this.tsaExpandButtonTarget.classList.remove("hidden");
// 		this.tsaCloseButtonTarget.classList.add("hidden");
// 	}

// 	expandNewsContainer(){
// 		this.newsContainerTarget.classList.add("col-span-4", "row-span-2");
// 		this.newsContainerTarget.classList.remove("col-span-2");

// 		for(let image of this.newsImageTargets) {
// 			image.classList.remove("h-11", "w-11");
// 			image.classList.add("h-16", "w-24");
// 		}

// 		this.resourcesContainerTarget.classList.add("hidden");
// 		this.videosContainerTarget.classList.add("hidden");
// 		this.graphsContainerTarget.classList.add("hidden");

// 		this.newsExpandButtonTarget.classList.add("hidden");
// 		this.newsCloseButtonTarget.classList.remove("hidden");
// 	}

// 	shrinkNewsContainer(){
// 		this.newsContainerTarget.classList.remove("col-span-4", "row-span-2");
// 		this.newsContainerTarget.classList.add("col-span-2");

// 		for(let image of this.newsImageTargets) {
// 			image.classList.remove("h-16", "w-24");
// 			image.classList.add("h-11", "w-11");
// 		}

// 		this.resourcesContainerTarget.classList.remove("hidden");
// 		this.videosContainerTarget.classList.remove("hidden");
// 		this.graphsContainerTarget.classList.remove("hidden");

// 		this.newsExpandButtonTarget.classList.remove("hidden");
// 		this.newsCloseButtonTarget.classList.add("hidden");
// 	}

// 	expandVideosContainer(){
// 		this.videosContainerTarget.classList.add("col-span-4", "row-span-2");
// 		this.videosContainerTarget.classList.remove("col-span-2");

// 		this.resourcesContainerTarget.classList.add("hidden");
// 		this.newsContainerTarget.classList.add("hidden");
// 		this.graphsContainerTarget.classList.add("hidden");

// 		this.videosExpandButtonTarget.classList.add("hidden");
// 		this.videosCloseButtonTarget.classList.remove("hidden");

// 		this.mainVideoTarget.classList.remove("h-full");
// 		this.mainVideoTarget.classList.add("h-[80%]");
// 		this.videoCarouselTarget.classList.remove("hidden");
// 	}

// 	shrinkVideosContainer(){
// 		this.videosContainerTarget.classList.remove("col-span-4", "row-span-2");
// 		this.videosContainerTarget.classList.add("col-span-2");

// 		this.resourcesContainerTarget.classList.remove("hidden");
// 		this.newsContainerTarget.classList.remove("hidden");
// 		this.graphsContainerTarget.classList.remove("hidden");

// 		this.videosExpandButtonTarget.classList.remove("hidden");
// 		this.videosCloseButtonTarget.classList.add("hidden");

// 		this.mainVideoTarget.classList.remove("h-[80%]");
// 		this.mainVideoTarget.classList.add("h-full");
// 		this.videoCarouselTarget.classList.add("hidden");
// 	}
// }
